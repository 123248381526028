import {useTenantsQuery} from '@graphql/graphql';
import type {ComputedRef, Ref} from 'vue';

type UseTenantsArguments = {
  includeAll?: boolean,
  onlyCongregations?: boolean
};

export function useTenants(args?: ComputedRef<UseTenantsArguments> | Ref<UseTenantsArguments> | UseTenantsArguments) {
  const resolvedArgs = isRef(args) ? args : toRef(args || {});

  const {result} = useTenantsQuery(() => ({
    onlyCongregations: resolvedArgs.value?.onlyCongregations ?? false,
  }));

  const tenants = computed(() => result.value?.tenants ?? []);

  const options = computed<Array<{ label: string, value: number }>>(() => {
    const options = [];

    if (resolvedArgs.value?.includeAll) {
      options.push({label: 'Alle gemeenten', value: 0});
    }

    return [
      ...options,
      ...tenants.value.map(tenant => ({
        label: tenant.title,
        value: tenant.id,
      })),
    ];
  });

  return {
    options,
    tenants,
  };
}
